<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

import type { BannerPresetPackage, HomeBanners } from "@/types";

const props = defineProps<{
	banners: (HomeBanners[number] | NonNullable<BannerPresetPackage & { type: string }>)[];
	loading?: boolean;
}>();

const sliderRef = ref();

const sliderOptions: KeenSliderOptions<{ autoplay?: { delay: number } }> = {
	defaultAnimation: {
		duration: 1000
	},
	slides: { perView: 1, spacing: 24 },
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				spacing: 16
			}
		}
	},
	autoplay: { delay: 5000 }
};

watch(
	() => props.banners.length,
	() => {
		nextTick(() => {
			sliderRef.value?.slider?.update();
		});
	}
);
</script>
<template>
	<div class="banners">
		<LazyASlider ref="sliderRef" :options="sliderOptions">
			<template v-for="(banner, index) in banners">
				<OHomeBannerPresetPackage
					v-if="banner.type === 'bannerPresetPackage'"
					:key="`banner_preset_${index}`"
					class="keen-slider__slide"
					:banner="banner as BannerPresetPackage"
				/>

				<OHomeMainBanner
					v-else
					:key="`banner_${index}`"
					class="keen-slider__slide"
					:banner="banner as HomeBanners[number]"
				/>
			</template>

			<template #dots="{ slider, activeIndex }">
				<div v-if="banners.length > 1" class="pagination">
					<div
						v-for="index in banners.length"
						:key="index"
						:class="['pagination-item', { active: activeIndex === index - 1 }]"
						@click="slider?.moveToIdx(index - 1)"
					/>
				</div>
			</template>
		</LazyASlider>
	</div>
</template>
<style lang="scss" scoped>
.banners {
	position: relative;
	min-height: 310px;

	@include media-breakpoint-down(md) {
		min-height: 273px;
	}

	.pagination {
		display: flex;
		gap: gutter(1.5);
		margin-top: gutter(2);
		justify-content: center;

		&-item {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background-color: var(--chongjin);
			cursor: pointer;

			&.active {
				pointer-events: none;
				background-color: var(--calan);
			}
		}
	}
}
</style>
